<template>
  <BaseDialog
    width="600px"
    title="期限設定"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="250px"
    >
      <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
        <p class="font-bold"><span class="text-danger">* </span>有效期限</p>
        <el-tooltip placement="right">
          <div slot="content">
            當您更改有效期限時，不會影響顧客已擁有的點<br>
            數日期，僅會影響未來發生的操作或交易
          </div>
          <span class="material-icons">help_outline</span>
        </el-tooltip>
      </div>
      <el-form-item prop="daysUntilExpiration">
        <el-input v-model="formData.daysUntilExpiration" placeholder="請輸入" />
      </el-form-item>
      <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
        <p class="font-bold"><span class="text-danger">* </span>到期通知發送天數設定</p>
        <el-tooltip placement="right">
          <div slot="content">
            系統將於您在到期日前 X 天的中午 12 點推播 Line<br>
            訊息提醒。舉例：若設定到期日前 3 天通知假設<br>
            某位客人的點數是 12/31 到期，系統將於 12/28<br>
            的中午 12 點提醒
          </div>
          <span class="material-icons">help_outline</span>
        </el-tooltip>
      </div>
      <el-form-item prop="daysDiffExpirationNotify">
        <el-input v-model="formData.daysDiffExpirationNotify" placeholder="請輸入" />
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { UpdateShopPointYearExpiration } from '@/api/shopPoint'
import formUtils from '@/utils/form'
import { noEmptyRules } from '@/validation'
import store from '@/store'

export default defineComponent({
  name: 'PointExpiryEditModal',
  components: {
    BaseDialog,
  },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const formRef = ref(null)
    const formData = ref({
      daysDiffExpirationNotify: null,
      daysUntilExpiration: null,
    })
    const loading = ref(null)
    const shopId = computed(() => store.getters.shop)

    const formRules = {
      daysDiffExpirationNotify: [noEmptyRules()],
      daysUntilExpiration: [noEmptyRules()],
    }

    const syncData = () => {
      const { daysDiffExpirationNotify, daysUntilExpiration } = props.data
      formData.value = {
        daysDiffExpirationNotify,
        daysUntilExpiration,
      }
    }

    const updateShopPointYearExpiration = async () => {
      loading.value = true
      const { daysDiffExpirationNotify, daysUntilExpiration } = formData.value
      const [, err] = await UpdateShopPointYearExpiration({
        shopId: shopId.value,
        daysDiffExpirationNotify,
        daysUntilExpiration,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const formPass = await formUtils.checkForm(formRef.value)
      if (!formPass) {
        loading.value = false
        return
      }
      await updateShopPointYearExpiration()
      loading.value = false
      emit('refresh')
      emit('close')
    }

    onMounted(() => {
      syncData()
    })
    return {
      formRef,
      formData,
      formRules,
      onConfirm,
    }
  },
})
</script>
