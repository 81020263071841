<template>
  <div class="point-setting">
    <PageTitle
      title="點數模組參數設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <el-form v-if="!get(shopPoint, 'pointClientId')" label-position="left" label-width="180px">
      <el-form-item label="是否開啟點數模組：">
        <el-button v-loading="loading" type="primary" @click="registerPoint">開啟</el-button>
      </el-form-item>
    </el-form>
    <div v-if="get(shopPoint, 'pointClientId')">
      <PointExpirySetting />
      <PointNameSetting />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { get } from 'lodash'
import PageTitle from '@/components/Title/PageTitle.vue'
import PointExpirySetting from './components/PointExpirySetting.vue'
import PointNameSetting from './components/PointNameSetting.vue'
import { RegisterShopRebate, FindShopPoint } from '@/api/shopPoint'
import store from '@/store'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'pointSettings',
  components: {
    PageTitle,
    PointExpirySetting,
    PointNameSetting,
  },
  setup () {
    const loading = ref(false)
    const shopId = computed(() => store.getters.shop)
    const shopPoint = ref(null)

    const registerPoint = async () => {
      if (loading.value) return
      loading.value = true
      const [, err] = await RegisterShopRebate({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      await findShopPoint()
      loading.value = false
    }

    const findShopPoint = async () => {
      loading.value = true
      const [res, err] = await FindShopPoint({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      shopPoint.value = res
    }

    onMounted(async () => {
      loading.value = true
      await findShopPoint()
      loading.value = false
    })

    return {
      get,
      loading,
      shopPoint,
      registerPoint,
    }
  },
})
</script>
