<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="點數顯示名稱設定" @edit="modal.edit = true" />
    <div>
      <el-form
        v-model="formData"
        label-position="left"
        label-width="250px"
      >
        <el-form-item label="顯示名稱" prop="name">
          <div class="secret-text collapse">
            <span>{{ formData.name }}</span>
          </div>
        </el-form-item>
        <el-form-item label="顯示單位" prop="unitName">
          <div class="secret-text collapse">
            <span>{{ formData.unitName }}</span>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <PointNameEditModal
      v-if="modal.edit"
      :data="formData"
      @close="modal.edit = false"
      @refresh="findShopPoint"
    />
  </section>
</template>

<script>
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import PointNameEditModal from './PointNameEditModal.vue'
import { FindShopPoint } from '@/api/shopPoint'
import store from '@/store'

export default defineComponent({
  name: 'PointNameSetting',
  components: {
    PointNameEditModal,
  },
  // emits: ['refresh'],
  setup () {
    const loading = ref(null)
    const shopId = computed(() => store.getters.shop)
    const formData = ref({})
    const modal = reactive({
      edit: false,
    })

    const findShopPoint = async () => {
      loading.value = true
      const [res, err] = await FindShopPoint({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      formData.value = res
    }

    onMounted(() => {
      findShopPoint()
    })
    return {
      modal,
      formData,
      loading,
      findShopPoint,
    }
  },
})
</script>
