<template>
  <BaseDialog
    width="600px"
    title="點數顯示名稱設定"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <el-form
      ref="formRef"
      label-position="top"
      label-width="250px"
      :model="formData"
      :rules="formRules"
    >
      <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
        <p class="font-bold"><span class="text-danger">* </span>顯示名稱</p>
        <el-tooltip placement="right">
          <div slot="content">
            您可以自行設定點數在客人端顯示的文字，至多 5<br>
            個字。例如：紅利點數、Point 等
          </div>
          <span class="material-icons">help_outline</span>
        </el-tooltip>
      </div>
      <el-form-item prop="name">
        <el-input v-model="formData.name" maxlength="5" show-word-limit placeholder="請輸入" />
      </el-form-item>
      <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
        <p class="font-bold"><span class="text-danger">* </span>顯示單位</p>
        <el-tooltip placement="right">
          <div slot="content">
            您可以自行設定點數在客人端顯示的文字，至多 3個字。例如：點、dot 等
          </div>
          <span class="material-icons">help_outline</span>
        </el-tooltip>
      </div>
      <el-form-item prop="unitName">
        <el-input v-model="formData.unitName" maxlength="3" show-word-limit placeholder="請輸入" />
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import formUtils from '@/utils/form'
import { noEmptyRules } from '@/validation'
import { UpdateShopPoint } from '@/api/shopPoint'
import store from '@/store'

export default defineComponent({
  name: 'PointExpiryEditModal',
  components: {
    BaseDialog,
  },
  props: {
    data: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const loading = ref(null)
    const formRef = ref(null)
    const formData = ref({
      name: null,
      unitName: null,
    })
    const shopId = computed(() => store.getters.shop)

    const syncData = () => {
      const { name, unitName } = props.data
      formData.value = {
        name,
        unitName,
      }
    }

    const formRules = {
      name: [noEmptyRules()],
      unitName: [noEmptyRules()],
    }

    const updateShopPoint = async () => {
      loading.value = true
      const { name, unitName } = formData.value
      const [, err] = await UpdateShopPoint({
        shopId: shopId.value,
        name,
        unitName,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const formPass = await formUtils.checkForm(formRef.value)
      if (!formPass) {
        loading.value = false
        return
      }
      await updateShopPoint()
      loading.value = false
      emit('refresh')
      emit('close')
    }

    onMounted(() => {
      console.log('tt', props.data)
      syncData()
    })
    return {
      formRef,
      formData,
      formRules,
      onConfirm,
    }
  },
})
</script>
